<template>
  <div class="rno-pages-wrap" v-show="pageTotal > 1">
    <div class="rno-pages">
      <a v-show="!prevDisable" :class="['rno-pages-item', 'rno-pages-item-prev', { disabled: prevDisable }]" href="javascript:void(0)" @click.prevent="jumpPrev()">上一页</a>
      <template v-for="i in pageTotal">
        <a
          v-if="i == 1 || i == pageTotal || (pageNo - 2 <= i && i <= pageNo + 2)"
          :class="['rno-pages-item', { 'rno-pages-item-active': i == pageNo }]"
          href="javascript:void(0)"
          @click.prevent="jump(i)"
          >{{ i }}</a
        >
        <span v-else-if="(i == 2 && pageNo >= 5) || (i == pageTotal - 1 && pageNo <= pageTotal - 4)" class="rno-pages-dot">...</span>
      </template>
      <a v-show="!nextDisable" :class="['rno-pages-item', 'rno-pages-item-next', { disabled: nextDisable }]" href="javascript:void(0)" @click.prevent="jumpNext()">下一页</a>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'pageNo'
  },
  props: {
    pageTotal: {
      type: Number,
      default: 1
    },
    pageNo: {
      type: Number,
      default: 1
    },
    pageName: ''
  },
  computed: {
    // “上一页”按钮是否可点
    prevDisable: function () {
      return this.pageTotal <= 1 || this.pageNo <= 1
    },
    // “下一页”按钮是否可点
    nextDisable: function () {
      return this.pageTotal <= 1 || this.pageNo >= this.pageTotal
    }
  },
  methods: {
    //点击上一页
    jumpPrev: function () {
      if (this.prevDisable) {
        return
      }
      this.$emit('on-jump', this.pageNo - 1, this.pageName)
    },
    //点击下一页
    jumpNext: function () {
      if (this.nextDisable) {
        return
      }
      this.$emit('on-jump', this.pageNo + 1, this.pageName) //跳转
    },
    //直接跳转
    jump: function (id) {
      if (id > this.pageTotal) {
        id = this.pageTotal
      }
      this.$emit('on-jump', id, this.pageName) //跳转
    }
  }
}
</script>
