<template>
  <div class="qc-scrollbar rno-scrollbar-tools J-qc-scrollbar">
      <div class="qc-scrollbar-inner rno-scrollbar-tools-inner">
          <div class="qc-scrollbar-item qc-scrollbar-event rno-scrollbar-tools-item rno-scrollbar-tools-event">
              <div class="qc-scrollbar-bubble J-qc-scrollbar-bubble J-event-bubble" hotrep=""
                  style="top: 50%; margin-top: 0px;"> </div>
          </div>
          <div class="qc-scrollbar-item rno-scrollbar-tools-item rno-scrollbar-tools-service J-connectUsBlock"> <a
                  class="rno-scrollbar-tools-button rno-scrollbar-tools-toggle J-btnConnectUs"
                  hotrep="hp.sidebar.connectus" title="联系我们" style="text-decoration: none;"> <img
                      class="rno-scrollbar-tools-icon qc-scrollbar-icon"
                      src="https://main.qcloudimg.com/raw/3bc4782fbfb8375d9a4e54e481298f82/message.svg"
                      alt="message-icon"> <span
                      class="rno-scrollbar-tools-button-desc qc-scrollbar-button-desc J-scrollbar-button-connect-us">联系我们</span>
              </a>
              <div class="J-expandPanel"
                  style="">
                  <div class="rno-scrollbar-tools-bubble" style="position: static;">
                      <div class="rno-scrollbar-tools-bubble-inner">
                         <div class="market-contact-tit">
                             <i class="market-contact-tit-icon"></i>
                             <span class="market-contact-tit-text">腾讯云物联网市场</span>
                         </div>
                         <p class="market-contact-desc">您好！我是腾讯云物联网市场商务经理，对于物联网市场入驻，项目商机合作，线上线下展会，联合宣传推广或者对于物联网市场的建议及需求等都可以直接与我联系。</p>
                         <ul class="market-contact-mode-list">
                             <li class="market-contact-mode-item">
                                 <p class="market-contact-mode-tit">
                                     <i class="market-contact-mode-icon market-contact-mode-icon-man"></i>
                                     <span class="market-contact-mode-tit-text">联系人</span>
                                 </p>
                                 <p class="market-contact-mode-tit-desc">物联网市场商务经理</p>
                             </li>
                             <li class="market-contact-mode-item">
                              <p class="market-contact-mode-tit">
                                  <i class="market-contact-mode-icon market-contact-mode-icon-qq"></i>
                                  <span class="market-contact-mode-tit-text">联系客服</span>
                              </p>
                              <p class="market-contact-mode-tit-desc">
															<span class="market-contact-text-span" onclick="window.open('http://wpa.qq.com/msgrd?v=3&site=qq&menu=yes&uin=1692228780')" style="cursor: pointer">QQ: 1692228780</span>
														</p>
                          </li>
                          <li class="market-contact-mode-item">
                              <p class="market-contact-mode-tit">
                                  <i class="market-contact-mode-icon market-contact-mode-icon-emile"></i>
                                  <span class="market-contact-mode-tit-text">联系邮箱</span>
                              </p>
                              <p class="market-contact-mode-tit-desc">
															<span class="market-contact-text-span" onclick="window.open('mailto:iotmarket@tencent.com?subject=您的问题&cc=抄送人邮箱&subject=主题&body=内容')" style="cursor: pointer">iotmarket@tencent.com</span>
														</p>
                          </li>
                         </ul>
                         <a class="rno-btn rno-btn-primary" href="https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97832303cf261699ee1eb5b6618e200789d44852a1dd946eaa9c7dc5c30643e3b004dc7032359ceb9a7c5f25ddc202a014" target="blank"><span class="rno-btn-text">立即咨询</span></a>
                      </div>
                  </div>
              </div>
              <div class="rno-scrollbar-tools-bubble-m J-expandPanelM"
                  style="">
                  <div class="rno-scrollbar-tools-bubble-m-inner"> 
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'ContactTip',
}
</script>
<style lang="less" scoped>
.market-contact-text-span:hover {
  color: #00a4ff
}
</style>
