<template>
  <div class="rno-section market-solution-section bg-color">
    <div class="rno-section-inner">
      <div class="rno-section-hd">
        <h3 class="rno-section-tit">{{ currentConfig.mainTitle }}</h3>
        <span class="rno-section-text">共 {{ listData.total }} 个结果</span>
      </div>
      <div class="rno-section-bd">
        <div v-if="listData.total > 0">
          <component :is="current" :list-data="listData"></component>
          <page-box-v2 v-if="needPage" :pageTotal="pageTotal" v-model="pageNo" @on-jump="jumpPage"></page-box-v2>
        </div>
        <div class="market-no-result" v-else>
          <i class="market-no-result-icon"></i>
          <span class="market-no-result-span">{{ currentConfig.mainEmptyTxt1 }}</span>
          <p class="market-no-result-p">{{ currentConfig.mainEmptyTxt2 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getUrlParma } from '../../util/jumpPage'
import PageBoxV2 from '_c/PageBoxV2'

export default {
  name: 'MainlistV2',
  props: {
    current: {
      type: String,
      default: ''
    },
    needPage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pageNo: 1 // 当前页码
    }
  },
  computed: {
    ...mapState('componentCategorys', {
      categoryId1: (s) => s.categoryId1,
      categoryId2: (s) => s.categoryId2,
      solutionTypeId: (s) => s.solutionTypeId,
      sceneTypeId: (s) => s.sceneTypeId,
      hardwareTypeId: (s) => s.hardwareTypeId,
      communicationTypeId: (s) => s.communicationTypeId,
      serviceTypeId: (s) => s.serviceTypeId,
    }),
    ...mapState('commonData', {
      type: (s) => s.typeList.currentType
    }),
    ...mapState('commonData', {
      pageConfig: (s) => s.pageConfig
    }),
    currentConfig() {
      return this.pageConfig[this.current] || {}
    },
    pageLimit() {
      return this.currentConfig.mainLimit
    },
    pageTotal() {
      return Math.ceil(this.listData.total / this.pageLimit) || 0
    },
    listData() {
      const SELF = this
      let current = this.current
      if (!current) {
        return {
          list: [],
          total: 0
        }
      }

      // 换成首字母大写。。。
      current = current.substr(0, 1).toUpperCase() + current.substr(1)
      let pageSpace = `data${current}`
      let mainList = SELF.$store.state[pageSpace].mainList

      return mainList
    }
  },
  components: {
    PageBoxV2,
    solution: function (resolve) {
      require(['_c/mainlistv2/Solution.vue'], resolve)
    },
    module: function (resolve) {
      require(['_c/mainlistv2/Module.vue'], resolve)
    },
    provider: function (resolve) {
      require(['_c/mainlistv2/Provider.vue'], resolve)
    },
    partner: function (resolve) {
      require(['_c/mainlistv2/Provider.vue'], resolve)
    },
  },
  methods: {
    ...mapActions({
      getSolutionList: 'dataSolution/getMainList',
      getModuleList: 'dataModule/getMainList',
      getProviderList: 'dataProvider/getProviderList',
      getPartnerList: 'dataPartner/getPartnerList',
    }),
    resetPage() {
      this.pageNo = 1
    },
    jumpPage(i) {
      // window.scroll(0,0)
      this.pageNo = i
      this.getList()
    },
    getList() {
      const SELF = this
      // 各种页面公共配置
      let options = {
        Limit: SELF.pageLimit,
        Offset: SELF.pageLimit * (SELF.pageNo - 1) || 0
      }

      // 差异化配置
      switch (SELF.current) {
        case 'solution':
          options.name = getUrlParma('search') || ''
          SELF.solutionTypeId && (options.SolutionType = SELF.solutionTypeId)
          SELF.sceneTypeId && (options.SceneType = SELF.sceneTypeId)
          SELF.getSolutionList(options)
          break
        case 'module':
          options.name = getUrlParma('search') || ''
          SELF.hardwareTypeId && (options.hardwareType = SELF.hardwareTypeId)
          SELF.communicationTypeId && (options.communicationType = SELF.communicationTypeId)
          SELF.getModuleList(options)
          break
        case 'provider':
          options.name = getUrlParma('search') || ''
          SELF.serviceTypeId && (options.serviceType = SELF.serviceTypeId)
          SELF.sceneTypeId && (options.sceneType = SELF.communicationTypeId)
          SELF.getProviderList(options)
          break
        case 'partner':
          options.name = getUrlParma('search') || ''
          SELF.serviceTypeId && (options.serviceType = SELF.serviceTypeId)
          SELF.sceneTypeId && (options.sceneType = SELF.communicationTypeId)
          SELF.getPartnerList(options)
          break
      }
    },
  },
}
</script>
