<template>
  <div class="rno-searching-box page-courses-selectarea coll-mod-pc" id="category">
    <div class="coll-area-wrap">
      <div class="coll-area-form">
        <div class="coll-area-form-item" v-for="(item, index) in dataFields" :key="index">
          <div class="coll-area-form-label">{{item.name}}：</div>
          <div class="coll-area-form-con">
            <div class="coll-selectarea-radio-block">
              <a :class="['coll-selectarea-radio-block-item', {'actived':+selection[item.shortField] === 0}]" href="javascript:void(0)" @click.prevent="changeSelect(item, 0)"><span>全部</span></a>
              <a v-for="(option, optIdx) in item.options" :key="optIdx" :class="['coll-selectarea-radio-block-item', {'actived':+selection[item.shortField] === +option.key}]" href="javascript:void(0)" @click.prevent="changeSelect(item, option.key)"><span>{{option.value}}</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { jump, getUrlParma } from '../util/jumpPage'

export default {
  name: 'CategorysBoxV2',
  props: {
    /**
      字段配置
      eg: [
        {
          field: 'solutionTypeId', //store里的字段名
          shortField: 'stype', //url里的字段名
          name: '方案类型',
          options: this.solutionTypeList
        },
        {
          field: 'sceneTypeId',
          shortField: 'ctype',
          name: '应用场景',
          options: this.sceneTypeList
        }
      ]
     */
    dataFields: {
      type: Array
    }
  },
  computed: {
    ...mapState('componentCategorys', {
      totalData: s => s
    }),
    selection() {
      let obj = {}
      // this.dataFields?.map((item) => {
      //   const keyArr = item.field?.split('.')
      //   if (keyArr.length === 2) {
      //     obj[item.shortKey] = +this.totalData[keyArr[0]][keyArr[1]]
      //   } else {
      //     obj[item.shortKey] = +this.totalData[item.key]
      //   }
      // })
      this.dataFields?.map(item => {
        obj[item.shortField] = +this.totalData[item.field]
      })
      return obj
    }
  },
  methods: {
    changeSelect(item, optionKey){
      let currentPage = this.$router.history.current.name
      let pageParmas = {
        ...this.selection
      }

      pageParmas[item.shortField] = +optionKey

      let search = getUrlParma('search')
      if (search) {
        pageParmas.search = search
      }

      jump(this,{
        page: currentPage,
        deep: true,
        pageParmas,
        anchor: '#category'
      })
    }
  }
}
</script>
